.container{
  display: grid !important;
  grid-column: full-start / full-end !important;
  min-height: 700px !important;
  grid-template-columns: [full-start] 1fr [main-start] minmax(666px, auto) minmax(auto, 906px) [main-end] 1fr [full-end] !important;
  background: var(--sauge) !important;
}

.titleWrapper{
  display: flex;
  flex-flow: column wrap;
  grid-column: full-start / main-start;
  height: 100%;
  background: var(--sauge);
}

.titleContainer{
  display: flex;
  flex-flow: column wrap;
  min-width: 666px;
  width: min-content;
  background: var(--sauge);
  justify-content: center;
  padding: 0;
  grid-column: main-start / 2 !important;
}

.title{
  position: relative;
  display: inline-block;
  font: var(--font-h1-desktop-default);
  color: var(--white);
  font-weight: 800;
  height: fit-content;
  width: 100%;
}

.content{
  display: flex;
  gap: var(--spacing-40);
  flex-flow: column wrap;
  padding: 0 var(--spacing-80);
  color: var(--white);
  height: fit-content;
}

.icon{
  --size-icon: 24px;
  margin-left: 4px;
  height: var(--size-icon);
  width: var(--size-icon);
}

.heroContainer{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  flex: 1 0 auto;
  background: var(--beige);
  padding: 0 !important;
  grid-column: 3 / full-end !important;
}

.heroImg{
  position: relative;
  width: 100%;
  flex: 1 0 auto;
  background: var(--gray);
  clip-path: inset(0 0 0 0);
}

.hasYoutube{
  cursor: none;
}

.isDesktop{
  display: grid;
}

.isMobile{
  display: none;
}

@media (max-width: 1200px){
  .titleContainer{
    padding: var(--spacing-40) var(--spacing-24);
  }
  .searchBar{
    padding: var(--spacing-40) var(--spacing-40) var(--spacing-80);
  }
  .videoButton{
    top: var(--spacing-40);
    right: var(--spacing-40);
  }
}

@media (max-width: 1024px){
  .container{
    min-height: auto !important;
    grid-template-columns: var(--grid-template-mobile) !important;
  }
  .searchBar{
    padding: 0 var(--spacing-24);
  }
  .heroContainer{
    display: none;
  }
  .titleContainer{
    padding: 0;
    min-width: 100%;
    grid-column: full-start / full-end !important;
    grid-template-columns: var(--grid-template-mobile);
  }
  .title{
    font: var(--font-h2-mobile-default);
    font-weight: 800;
    padding-inline: 0;
  }
  .videoButton{
    --size-icon: 85px;
  
    top: var(--spacing-24);
    right: var(--spacing-24);
  }
  .content{
    padding: 0;
  }
  .link {
    padding: 0;
  }
  .link > a {
    width: 100%;
  }
  .videoLabel{
    font: var(--font-p3-mobile-default);
    font-weight: bold;
  }
  .content {
    padding: var(--spacing-40) var(--spacing-24);
    padding-bottom: 0;
    order: 2;
  }
  .link{
    order: 3;
    padding: var(--spacing-40) var(--spacing-24);
    padding-top: 0;
  }
  .heroImg.isMobile{
    height: 440px;
    display: flex;
    order: 1;
    width: 100%;
  }
}

@media (max-width: 768px){
  .titleContainer{
    grid-column: full-start / full-end !important;
    grid-template-columns: var(--grid-template-mobile);
  }
  .titleContainer{
    min-width: 100%;
  }
  .title{
    grid-column: full-start / 9;
  }
  .heroImg{
    grid-column: 9 / full-end;
  }

  .heroContainer{
    grid-column: full-start / full-end !important;
  }

  .isDesktop{
    display: none;
  }

  .heroImg.isMobile{
    height: 223px;
  }
}

@media (max-width: 608px) {
  .container{
    min-height: auto;
  }

  .title{
    padding-inline: 0 !important;
  }
}

@media (max-width: 480px) {
  .title{
    grid-column: full-start / full-end !important;
  }
}
